<template>
  <section class="banner-area">
    <h1 class="banner_heading" v-if="type === 'flight-only'">BLUE BIRD AIR</h1>
    <div v-if="showSlider">
      <b-carousel
        v-model="slide"
        :interval="20000"
        indicators
        ref="myCarousel"
        class="banner-carousel"
        :class="hideMoble && 'hide-mobile'"
        style="text-shadow: 1px 1px 2px #333"
      >
        <b-carousel-slide
          :img-src="item"
          v-for="(item, index) in imageList"
          :key="index"
          img-alt="home-banner"
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <div v-else class="default-background"></div>
  </section>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';

export default {
  name: 'Banner',
  components: {
    BCarousel,
    BCarouselSlide,
  },
  props: {
    imageList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    showSlider: {
      type: Boolean,
      default: true,
    },
    hideMoble: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slide: 0,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };
  },
  mounted() {
    // console.log(this.imageList, this.showSlider);
  },
};
</script>

<style>
.carousel.banner-carousel.slide .carousel-inner .carousel-item,
.carousel.banner-carousel.slide .carousel-inner .carousel-item .img-fluid {
  height: inherit;
}
.carousel-indicators li {
  display: inline-block;
  zoom: 1;
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;

  height: 1em;
  width: 1em;
  background-color: #ffffffa8;
  transition: all 0.5s ease-in-out;
  margin: 5px 7px;
  border-radius: 30px;
}
.carousel-indicators .active {
  background-color: #00ffff;
}
.default-background{
  height: 350px;
  width:100%;
}
@media (max-width: 479px) {
  .hide-mobile {
    display: none;
  }
  .carousel-indicators{
    padding: 0px;
  }
}
@media (min-width: 1200px) {
  .carousel-indicators {
    transform: rotate(-90deg) translateY(-50%) scale(1, -1);
    right: 0;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    display: block;
    justify-content: unset;
    padding: inherit;
    margin: auto;
  }
  .carousel-inner {
    height: calc(100vh - 56px);
  }
}
.banner-area .slide {
  height: auto;
}
</style>
